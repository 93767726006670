import facebookicon from "./facebookicon.svg";
import instagramicon from "./instagramicon.svg";
import tiktokicon from "./tiktokicon.svg"
import mercadopagoicon from "./mercadopagoicon.svg"
import patreonicon from "./patreonicon.svg"
import paypalicon from "./paypalicon.svg"

export const facebook = facebookicon;
export const instagram = instagramicon;
export const tiktok = tiktokicon;
export const mercadopago = mercadopagoicon;
export const patreon = patreonicon;
export const paypal = paypalicon;