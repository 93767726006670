import React from 'react'
import Home from '../containers/Home'
import OldHome from '../containers/OldHome'
import About from '../containers/About'

export default function Router() {
    if (window.location.pathname === "/about") {
        return (
            <About></About>
        )
    } else {
        return (
            <Home></Home>
        )
    }

}
