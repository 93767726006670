import React from 'react'
import { Row, Col } from 'react-bootstrap'
import logo from '../images/logo/getoutLogo.png'

export default function Home() {
    return (
        <Row>
            <Col xs={2}>
            </Col>
            <Col xs={6} style={{
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <h1 style={{ fontSize: "9vh", color: "white" }}>asdfasdfasdf</h1>
                <h3 style={{ color: "white" }}>xcxxxxxxxxxxxx</h3>
            </Col>
            <Col style={{
                height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <img style={{ width: "20vw" }} src={logo}></img>
            </Col>
        </Row>
    )
}
