import './App.css';
import NavBar from './components/NavBar/NavBar';
import { Container } from 'react-bootstrap'
import Router from './navigation/Router';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFire, faWandSparkles, faHandshakeAngle, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

library.add(faFire, faWandSparkles, faHandshakeAngle, faEnvelope)

function App() {
  return (
    <div>
      {/* <NavBar></NavBar> */}
      <Container fluid style={{ paddingTop: "5vh" }}>
        <Router></Router>
      </Container>
    </div>
  );
}

export default App;
