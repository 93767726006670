import React from 'react'
import user from '../../images/user.png'
import './TeamMember.css'

export default function TeamMember({ name, image, mainRole, isMobile }) {
    return (
        <div className={!isMobile ? 'team-member-col' : 'team-member-col-mobile'}>
            <img className='team-member-image' src={image ? "./images/" + image : user}></img>
            <div className={!isMobile ? 'image-hover' : 'image-hover-mobile'}>
                <p className={!isMobile ? 'team-member-name' : 'team-member-name-mobile'}><span style={{ fontWeight: "bold" }}>{name}</span> <br />{mainRole}</p>
            </div>
        </div>
    )
}
