import React from 'react'
import logo from '../../images/logo/getoutLogo.png'
import logoSmall from '../../images/logo/getoutLogoSmall.png'
import { Row, Col } from 'react-bootstrap'

export default function AboutUs() {
    const isMobile = window.innerWidth <= 768;

    return (
        <Row style={{ height: isMobile ? "100vw" : "" }}>
            <Col className="d-none d-sm-flex" sm={2}>
            </Col>

            {/* Logo Mobile */}
            <Col className='d-flex d-sm-none' xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5vw" }}>
                <img style={{ width: "40%" }} src={logoSmall}></img>
            </Col>

            {/* Mobile */}
            <Col className="d-flex d-sm-none" xs={2}>
            </Col>
            <Col className='d-flex d-sm-none' xs={8} style={{
                height: "40vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <h1 style={{ fontSize: "6vw", color: "white", textAlign: "center" }}>About Us</h1>
                <h3 style={{ fontSize: "4vw", color: "white", textAlign: "center" }}>We are an indie video game studio that, inspired by our favorite games and our wish to create, work every day to offer the best experience through our games.</h3>
            </Col>

            {/* PC */}
            <Col className="d-none d-sm-flex" sm={4} style={{
                height: "40vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <h1 style={{ fontSize: "4vw", color: "white", textAlign: "center" }}>About Us</h1>
                <h3 style={{ fontSize: "2vw", color: "white", textAlign: "center" }}>We are an indie video game studio that, inspired by our favorite games and our wish to create, work every day to offer the best experience through our games.</h3>
            </Col>

            {/* Logo PC */}
            <Col
                className='d-none d-sm-flex'
                xs={4}
                style={{
                    height: "40vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <img style={{ width: "20vw" }} src={logo}></img>
            </Col>
        </Row>


    )
}
