import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import TeamMember from '../components/TeamMember/TeamMember'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import rald from '../images/PosterRALD.png'
import AboutUs from '../components/AboutUs/AboutUs';
import logo from '../images/logo/getoutLogo.png'
// import { facebookicon, instagramicon, tiktokicon } from '../images/icons';
import { facebook, instagram, mercadopago, patreon, paypal, tiktok } from '../images/icons';

export default function Home() {
    const team = [
        {
            name: "Tomas Granda",
            mainRole: "Programmer",
            image: "Tomas Cuadrada-min.jpg",
        },
        {
            name: "Alejandro Gonza",
            mainRole: "Programmer",
            image: "Ale Cuadrada-min.jpg",
        },
        {
            name: "Martin Toscano",
            mainRole: "3D Artist",
            image: "Tin Cuadrada-min.jpg",
        },
        {
            name: "Lara Foster",
            mainRole: "3D Artist",
            image: "Lara Cuadrada.jpg"
        },
        {
            name: "Santiago Calogero",
            mainRole: "Technical Artist",
            image: "Santi Cuadrada-min.png"
        },
        {
            name: "Tobias Lonardi",
            mainRole: "Programmer",
            image: "Tadeo Lonardi.jpg"
        }
    ]

    return (
        <span>
            <Row>
                <Col className="d-none d-sm-block">
                    <h1 style={{ fontSize: "6vw", color: "white", textAlign: "center" }}>GetOutStudio</h1>
                </Col>
                <Col className='d-block d-sm-none'>
                    <h1 style={{ fontSize: "10vw", color: "white", textAlign: "center" }}>GetOutStudio</h1>
                </Col>
            </Row>

            <AboutUs></AboutUs>

            <Row>
                <Col style={{ backgroundColor: "white", paddingTop: "3vw", paddingBottom: "3vw" }}>
                    <Row className="d-none d-sm-flex">
                        <Col xs={1} sm={3}></Col>
                        <Col sm={2} style={{ display: "flex", flexDirection: "column" }}>
                            <FontAwesomeIcon icon={["fa", "fire"]} style={{ textAlign: 'center', fontSize: "5vw", marginTop: "2vw" }} />

                            <h4 style={{ textAlign: "center", marginTop: "2vw" }}>Inspiration</h4>
                            <p style={{ textAlign: "center", marginTop: "5px" }}>We use our favorite games as Inspiration to improve our game experience and innovate in mechanics.</p>
                        </Col>
                        <Col sm={2} style={{ backgroundColor: "white", display: "flex", flexDirection: "column" }}>
                            <FontAwesomeIcon icon={["fa", "wand-sparkles"]} style={{ textAlign: 'center', fontSize: "5vw", marginTop: "2vw" }} />

                            <h4 style={{ textAlign: "center", marginTop: "2vw" }}>Fantasy</h4>
                            <p style={{ textAlign: "center", marginTop: "5px" }}>We make magical experiences focused on world building and storytelling.</p>
                        </Col>
                        <Col sm={2} style={{ backgroundColor: "white", display: "flex", flexDirection: "column" }}>
                            <FontAwesomeIcon icon={["fa", "handshake-angle"]} style={{ textAlign: 'center', fontSize: "5vw", marginTop: "2vw" }} />

                            <h4 style={{ textAlign: "center", marginTop: "2vw" }}>Companionship</h4>
                            <p style={{ textAlign: "center", marginTop: "5px" }}>We work as a team aiming to help each other in any type of situation and looking for a healthy work environment that boost personal and professional growth.</p>
                        </Col>
                    </Row>

                    <Row className="d-flex d-sm-none">
                        <Col>
                            <Row>
                                <Col xs={5} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <FontAwesomeIcon icon={["fa", "fire"]} style={{ textAlign: 'center', fontSize: "12vw", marginTop: "2vw" }} />
                                    <h4 style={{ textAlign: "center", marginTop: "2vw", fontSize: "4vw" }}>Inspiration</h4>
                                </Col>
                                <Col>
                                    <p style={{ textAlign: "center", marginTop: "5px" }}>We use our favorite games as Inspiration to improve our game experience and innovate in mechanics.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <FontAwesomeIcon icon={["fa", "wand-sparkles"]} style={{ textAlign: 'center', fontSize: "12vw", marginTop: "2vw" }} />

                                    <h4 style={{ textAlign: "center", marginTop: "2vw", fontSize: "4vw" }}>Fantasy</h4>
                                </Col>
                                <Col>
                                    <p style={{ textAlign: "center", marginTop: "5px" }}>We make magical experiences focused on world building and storytelling.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <FontAwesomeIcon icon={["fa", "handshake-angle"]} style={{ textAlign: 'center', fontSize: "12vw", marginTop: "2vw" }} />

                                    <h4 style={{ textAlign: "center", marginTop: "2vw", fontSize: "4vw" }}>Companionship</h4>
                                </Col>
                                <Col>
                                    <p style={{ textAlign: "center", marginTop: "5px" }}>We work as a team aiming to help each other in any type of situation and looking for a healthy work environment that boost personal and professional growth.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>

            </Row>


            <Row>
                <Col className='d-none d-sm-block'>
                    <h1 style={{ marginTop: "1.5vw", marginBottom: "3vw", fontSize: "3vw", color: "white", textAlign: "center" }}>Our Games</h1>
                </Col>

                <Col className="d-block d-sm-none">
                    <h1 style={{ marginTop: "1.5vw", marginBottom: "3vw", fontSize: "3vw", color: "white", textAlign: "center" }}>Our Games</h1>
                </Col>
            </Row>

            <Row>
                <Col sm={2}></Col>
                <Col sm={3} md={4}>
                    <img alt="Rald" style={{ width: "100%" }} src={rald}></img>
                </Col>
                <Col sm={4} style={{ display: "flex", flexDirection: "column" }}>
                    <Row>
                        <Col>
                            <h3 style={{ textAlign: 'center', color: 'white' }}>RALD</h3>
                            <h5 style={{ textAlign: 'center', color: 'white' }}>Rehabilitation at Lost Drift</h5>
                            <p style={{ textAlign: 'justify', color: 'white' }}>
                                "Rehabilitation at Lost Drift" is a transmedia horror game in which you'll have to help an alien to overcome his traumas and addictions using your cell phone and your creativity. The player will have access to the traumatic memories of GB-64, but in order to uncover his past, he must pay attention to the clues he left behind in our present. These are found in the streets of Buenos Aires and in their social networks. GB-64 is part of our society. Help him.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "auto" }}>
                        <Col style={{ textAlign: "center" }}>
                            <Button variant='light' href="https://getout-studio.itch.io/rehabilitation-at-lost-drift" target="_blank" >Download In-Development Version</Button>
                        </Col>
                    </Row>

                </Col>
                <Col sm={2}></Col>
            </Row>

            <Row style={{ marginTop: "2vw", backgroundColor: "white" }}>
                <Col className="d-none d-sm-block">
                    <h1 style={{ marginBottom: "3vw", marginTop: "1.5vwvh", fontSize: "3vw", color: "black", textAlign: "center" }}>Team</h1>
                </Col>
                <Col className="d-block d-sm-none">
                    <h1 style={{ marginBottom: "3vw", marginTop: "1.5vwvh", fontSize: "7vw", color: "black", textAlign: "center" }}>Team</h1>
                </Col>
            </Row>

            {
                groupArr(team, 5)
                    .map(tg =>
                    (
                        <Row key={Math.random(0, 1000)} className='d-none d-sm-flex' style={{ backgroundColor: "white", paddingBottom: "3vw", justifyContent: "center" }}>
                            <Col style={{ display: "flex", justifyContent: "center" }}>
                                {tg.map(t => (<TeamMember key={t.name} name={t.name} image={t.image} mainRole={t.mainRole}></TeamMember>))}
                            </Col>
                        </Row>
                    ))
            }

            {
                groupArr(team, 3)
                    .map(tg =>
                    (
                        <Row key={Math.random(0, 1000)} className='d-flex d-sm-none' style={{ backgroundColor: "white", paddingBottom: "3vw", justifyContent: "center" }}>
                            <Col style={{ display: "flex", justifyContent: "center" }}>
                                {tg.map(t => (<TeamMember isMobile key={t.name + "-mobile"} name={t.name} image={t.image} mainRole={t.mainRole}></TeamMember>))}
                            </Col>
                        </Row>
                    ))
            }

            <Row style={{ marginTop: "1vw", marginBottom: "1vw" }}>
                <Col xs={3}>
                </Col>
                <Col xs={1}>
                    <img style={{ width: "100%" }} src={logo} alt='getoutlogo'></img>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <h1 style={{ color: "white" }}>GetOut Studio</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <Row>
                                <Col>
                                    <h5 style={{ color: "white" }}>Contact:</h5>
                                    <ul style={{ color: "white" }}>
                                        <li>getoutstudio22@gmail.com</li>
                                        <li>tomas.granda@outlook.com</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2} style={{ fontSize: "20px", paddingLeft: "40px", paddingRight: "40px" }}>
                            <Row>
                                <Col>
                                    <h5 style={{ color: "white", textAlign: "center" }}>Our Media:</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <a><img style={{ filter: "invert(1)", width: "100%" }} src={tiktok} alt='tiktokicon' ></img></a>
                                </Col>
                                <Col>
                                    <a><img style={{ filter: "invert(1)", width: "100%" }} src={facebook} alt='facebookicon'></img></a>
                                </Col>
                                <Col>
                                    <a><img style={{ filter: "invert(1)", width: "100%" }} src={instagram} alt='instagramicon'></img></a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2}>
                            <Row>
                                <h5 style={{ color: "white", textAlign: "center" }}>Support us:</h5>
                            </Row>
                            <Row>
                                <Col>
                                    <a href='https://www.patreon.com/GetOutStudio'><img style={{ width: "60%" }} src={patreon} alt='patreonicon' ></img></a>
                                </Col>
                                <Col>
                                    <a><img style={{ width: "60%" }} src={paypal} alt='paypalicon' ></img></a>
                                </Col>
                                <Col>
                                    <a><img style={{ width: "60%" }} src={mercadopago} alt='mercadopagoicon'></img></a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2}>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </span >
    )
}

function groupArr(data, n) {
    var group = [];
    for (var i = 0, j = 0; i < data.length; i++) {
        if (i >= n && i % n === 0)
            j++;
        group[j] = group[j] || [];
        group[j].push(data[i])
    }
    return group;
}
